import { useCallback, useEffect, useState } from 'react';
import { useAuthentication } from '@afosto/auth-react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getChannel, listChannels } from '../../../queries';
import type { Channel } from '../../../types';

export const useChannelProvider = () => {
  const queryClient = useQueryClient();
  const { isAuthenticated, isAuthorizing, tenantId } = useAuthentication();

  const [channel, setChannel] = useState<Channel | null>(null);
  const [isInitializingChannel, setIsInitializingChannel] = useState(true);
  const [showSelectChannelDialog, setShowSelectChannelDialog] = useState(false);

  const channelStorageKey = `${tenantId}_channelId`;

  const { data: channelOptions, isFetched } = useQuery({
    ...listChannels({
      filterIsDeletedEq: 0,
      filterTypeEq: 'STOREFRONT',
      pageSize: 250,
    }),
    enabled: isAuthenticated,
    select: (response) => response?.data || [],
  });

  const getStoredChannelId = useCallback(() => {
    const selectedChannelId =
      window.localStorage.getItem(channelStorageKey) ?? '';

    try {
      if (selectedChannelId) {
        return JSON.parse(selectedChannelId);
      }

      return null;
    } catch (error) {
      return null;
    }
  }, [channelStorageKey]);

  const storeChannelId = useCallback(
    (selectedChannelId: string) => {
      window.localStorage.setItem(
        channelStorageKey,
        JSON.stringify(selectedChannelId)
      );
    },
    [channelStorageKey]
  );

  const closeSelectChannelDialog = useCallback(() => {
    setShowSelectChannelDialog(false);
  }, []);

  const openSelectChannelDialog = useCallback(() => {
    setShowSelectChannelDialog(true);
  }, []);

  const loadChannel = useCallback(
    async (id: string) => {
      const response = await queryClient.fetchQuery(getChannel(id));
      const { channel: loadedChannel } = response || {};

      if (!loadedChannel) {
        throw new Error('Channel could not be loaded');
      }

      setChannel(loadedChannel);
      return loadedChannel;
    },
    [queryClient]
  );

  const selectChannel = useCallback(
    async (selectedChannel: Channel) => {
      await loadChannel(selectedChannel.id);

      storeChannelId(selectedChannel.id);
      closeSelectChannelDialog();
    },
    [closeSelectChannelDialog, loadChannel, storeChannelId]
  );

  useEffect(() => {
    const initializeChannel = async () => {
      try {
        if (!isAuthorizing && isAuthenticated && tenantId && isFetched) {
          const selectedChannelId = getStoredChannelId();
          const selectedChannel = selectedChannelId
            ? (channelOptions || []).find(({ id }) => id === selectedChannelId)
            : null;

          if (selectedChannel) {
            await loadChannel(selectedChannel?.id);
          }

          if (!selectedChannel && channelOptions?.length === 1) {
            const [firstChannel] = channelOptions || [];

            await loadChannel(firstChannel.id);
            storeChannelId(firstChannel.id);
          } else if (!selectedChannel) {
            openSelectChannelDialog();
          }

          setIsInitializingChannel(false);
        } else if (!isAuthorizing && !isAuthenticated) {
          setIsInitializingChannel(false);
        }
      } catch (error) {
        openSelectChannelDialog();
        setIsInitializingChannel(false);
      }
    };

    initializeChannel().catch(() => {
      // Do nothing.
    });
  }, [
    channelOptions,
    getStoredChannelId,
    isAuthenticated,
    isAuthorizing,
    isFetched,
    loadChannel,
    openSelectChannelDialog,
    storeChannelId,
    tenantId,
  ]);

  return {
    channel,
    channelOptions: (channelOptions || []) as Channel[],
    closeSelectChannelDialog,
    isInitializingChannel,
    openSelectChannelDialog,
    selectChannel,
    setShowSelectChannelDialog,
    showSelectChannelDialog,
  };
};
