import {
  IntlProvider as ReactIntlProvider,
  type ResolvedIntlConfig,
} from 'react-intl';
import type { IntlProviderProps } from './types';
import { INTL_DEFAULT_LOCALE, INTL_MESSAGES } from './constants';

const handleIntlError: ResolvedIntlConfig['onError'] = (error) => {
  const showErrors = import.meta.env.VITE_SHOW_MISSING_TRANSLATIONS === true;

  if (
    !showErrors &&
    error?.message?.startsWith('[@formatjs/intl Error MISSING_TRANSLATION]')
  ) {
    return;
  }

  if (import.meta.env.DEV) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const IntlProvider = (props: IntlProviderProps) => {
  const { children } = props;
  const browserLocale = navigator ? navigator.language?.toLowerCase() : '';
  const locale = Object.keys(INTL_MESSAGES).includes(browserLocale)
    ? browserLocale
    : INTL_DEFAULT_LOCALE;

  return (
    <ReactIntlProvider
      locale={locale}
      messages={INTL_MESSAGES[locale as keyof typeof INTL_MESSAGES]}
      onError={handleIntlError}
    >
      {children}
    </ReactIntlProvider>
  );
};
