import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useMutation } from '@tanstack/react-query';
import { addQueryParamsToUrl, uuid } from '@afosto/utils';
import { enqueuePrintJob as enqueuePrintJobMutationRequest } from '../../../mutations';
import { usePrintCommands } from '../../../hooks/usePrintCommands';
import { translations } from '../translations';
import type { OrderPayment } from '../../../queries';
import type {
  Adjustment,
  Channel,
  Order,
  OrderItem,
  PaymentMethod,
  Vat,
} from '../../../types';

export interface UsePrintActionsOptions {
  channel: Channel | null;
  order: Order | null;
}

export interface PrintOrderInput {
  payment: OrderPayment | null;
  paymentMethod: PaymentMethod | null;
}

export const usePrintActions = (options: UsePrintActionsOptions) => {
  const { channel, order } = options || {};

  const intl = useIntl();
  const printCommands = usePrintCommands();

  const { mutateAsync: enqueuePrintJobMutation } = useMutation({
    mutationFn: enqueuePrintJobMutationRequest,
  });

  const createSplContent = useCallback((commands: object[] = []) => {
    return {
      contentType: 'SPL',
      content: btoa(JSON.stringify(commands)),
    };
  }, []);

  const formatCurrency = useCallback(
    (value: number, currency = 'EUR') => {
      return intl
        .formatNumber(value, {
          style: 'currency',
          currency,
          currencyDisplay: 'code',
        })
        .replace(currency, '')
        .trim();
    },
    [intl]
  );

  const openCashDrawer = useCallback(async () => {
    await enqueuePrintJobMutation({
      await: 'PRINTED',
      jobs: [
        {
          id: uuid(),
          printerId: 73705445,
          ...createSplContent(printCommands.peripheral()),
        },
      ],
    });
  }, [createSplContent, enqueuePrintJobMutation, printCommands]);

  const printOrder = useCallback(
    async (input?: PrintOrderInput) => {
      const { payment, paymentMethod } = input || {};

      if (!channel) {
        throw new Error('Channel not found');
      }

      if (!order) {
        throw new Error('Order not found');
      }

      await enqueuePrintJobMutation({
        await: 'PRINTED',
        jobs: [
          {
            id: uuid(),
            printerId: 73705445,
            ...createSplContent([
              ...printCommands.feed(),
              ...printCommands.feed(),
              // Heading
              ...(channel?.business
                ? printCommands.text(channel.business.name, { align: 'center' })
                : []),
              ...(channel?.business?.addressing.visiting
                ? printCommands.address(
                    channel.business.addressing.visiting,
                    channel.business.addressing.visiting.options?.format
                      ?.address || '',
                    { align: 'center' }
                  )
                : []),
              ...(channel?.business?.phoneNumber
                ? printCommands.text(
                    `${intl.formatMessage(
                      translations.phoneNumberAbbreviation
                    )}: ${channel.business.phoneNumber.number}`,
                    { align: 'center' }
                  )
                : []),
              ...printCommands.feed(),
              ...printCommands.line(),
              ...printCommands.feed(),
              // Items
              ...(order?.items || []).flatMap((item: OrderItem) => {
                const [firstDetail] = item.details || [];

                return [
                  ...printCommands.table([...printCommands.text(item.label)]),
                  // Quantity row
                  ...printCommands.table([
                    ...printCommands.text(
                      `  ${item.quantity}x ${formatCurrency(
                        (firstDetail?.pricing?.amount || 0) / 100
                      )}`,
                      { width: 22 }
                    ),
                    ...printCommands.text(formatCurrency(item.subtotal / 100), {
                      align: 'right',
                      width: 10,
                    }),
                    ...printCommands.text(
                      !item.adjustments?.length
                        ? formatCurrency(item.total / 100)
                        : '',
                      {
                        align: 'right',
                        width: 10,
                      }
                    ),
                  ]),
                  // Adjustments
                  ...((item.adjustments || []).filter(
                    (adjustment) => adjustment.outcome?.amount > 0
                  ).length > 0
                    ? [
                        ...item.adjustments.flatMap(
                          (adjustment: Adjustment, idx) =>
                            printCommands.table([
                              ...printCommands.text(
                                `  ${adjustment.description}${
                                  adjustment.isPercentage
                                    ? ` ${adjustment.amount}%`
                                    : ''
                                }`,
                                {
                                  width: 22,
                                }
                              ),
                              ...printCommands.text(
                                formatCurrency(
                                  ((adjustment.outcome?.amount || 0) *
                                    (adjustment.isDiscount ? -1 : 1)) /
                                    100
                                ),
                                { align: 'right', width: 10 }
                              ),
                              ...printCommands.text(
                                idx + 1 === item.adjustments.length
                                  ? formatCurrency(item.total / 100)
                                  : '',
                                { align: 'right', width: 10 }
                              ),
                            ])
                        ),
                      ]
                    : []),
                  ...printCommands.feed(),
                ];
              }),
              ...printCommands.line(),
              ...printCommands.feed(),
              // Subtotal
              ...printCommands.table([
                ...printCommands.text(
                  intl.formatMessage(translations.subtotal),
                  { width: 32 }
                ),
                ...printCommands.text(
                  formatCurrency(order.subtotal / 100, order.currency),
                  {
                    align: 'right',
                    width: 10,
                  }
                ),
              ]),
              // Adjustments
              ...((order.adjustments || []).filter(
                (adjustment) => adjustment.outcome?.amount > 0
              ).length > 0
                ? [
                    ...order.adjustments.flatMap((adjustment: Adjustment) =>
                      printCommands.table([
                        ...printCommands.text(
                          `${adjustment.description}${
                            adjustment.isPercentage
                              ? ` ${adjustment.amount}%`
                              : ''
                          }`,
                          {
                            width: 32,
                          }
                        ),
                        ...printCommands.text(
                          formatCurrency(
                            ((adjustment.outcome?.amount || 0) *
                              (adjustment.isDiscount ? -1 : 1)) /
                              100
                          ),
                          { align: 'right', width: 10 }
                        ),
                      ])
                    ),
                  ]
                : []),
              // Total
              ...printCommands.table([
                ...printCommands.text(intl.formatMessage(translations.total), {
                  style: 'bold',
                  width: 32,
                }),
                ...printCommands.text(
                  formatCurrency(order.total / 100, order.currency),
                  {
                    align: 'right',
                    style: 'bold',
                    width: 10,
                  }
                ),
              ]),
              // Payments
              ...(payment && paymentMethod
                ? printCommands.table([
                    ...printCommands.text(paymentMethod.name, {
                      width: 32,
                    }),
                    ...printCommands.text(
                      formatCurrency(payment.amountPaid / 100),
                      { align: 'right', width: 10 }
                    ),
                  ])
                : []),
              // VAT
              ...((order?.vat || []).length > 0
                ? [
                    ...printCommands.feed(),
                    ...order.vat.flatMap((vat: Vat) =>
                      printCommands.table([
                        ...printCommands.text(
                          intl.formatMessage(translations.vat),
                          { width: 22 }
                        ),
                        ...printCommands.text(`${vat.rate}%`, {
                          align: 'right',
                          width: 10,
                        }),
                        ...printCommands.text(
                          formatCurrency(vat.amount / 100, order.currency),
                          { align: 'right', width: 10 }
                        ),
                      ])
                    ),
                  ]
                : []),
              // PIN receipt
              ...(paymentMethod?.code?.toLowerCase() === 'pin' &&
              !paymentMethod.isManual &&
              payment &&
              payment?.metadata
                ? [
                    ...printCommands.feed(),
                    ...printCommands.line(),
                    ...printCommands.feed(),
                    ...printCommands.table([
                      ...printCommands.text(
                        intl.formatMessage(translations.terminal),
                        { width: 1 }
                      ),
                      ...printCommands.text(payment.metadata.terminal || '-', {
                        align: 'right',
                        width: 1,
                      }),
                    ]),
                    ...printCommands.table([
                      ...printCommands.text(
                        intl.formatMessage(translations.merchant),
                        { width: 1 }
                      ),
                      ...printCommands.text(payment.metadata.merchant || '-', {
                        align: 'right',
                        width: 1,
                      }),
                    ]),
                    ...printCommands.table([
                      ...printCommands.text(
                        intl.formatMessage(translations.period),
                        { width: 1 }
                      ),
                      ...printCommands.text(payment.metadata.period || '-', {
                        align: 'right',
                        width: 1,
                      }),
                    ]),
                    ...printCommands.table([
                      ...printCommands.text(
                        intl.formatMessage(translations.transaction),
                        { width: 1 }
                      ),
                      ...printCommands.text(
                        payment.metadata.transaction || '-',
                        { align: 'right', width: 1 }
                      ),
                    ]),
                    ...(payment.metadata.cardBrandName
                      ? printCommands.table([
                          ...printCommands.text(
                            payment.metadata.cardBrandName,
                            { width: 1 }
                          ),
                        ])
                      : []),
                    ...(payment.metadata.cardSerialNumber
                      ? printCommands.table([
                          ...printCommands.text(
                            intl.formatMessage(translations.cardSerialNumber),
                            { width: 1 }
                          ),
                          ...printCommands.text(
                            payment.metadata.cardSerialNumber,
                            { align: 'right', width: 1 }
                          ),
                        ])
                      : []),
                    ...printCommands.feed(),
                    ...printCommands.table([
                      ...printCommands.text(
                        intl.formatMessage(translations.payment).toUpperCase(),
                        { width: 1 }
                      ),
                    ]),
                    ...printCommands.table([
                      ...printCommands.text(
                        intl.formatMessage(translations.date),
                        { width: 1 }
                      ),
                      ...printCommands.text(
                        intl.formatDate(payment.paidAt, {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit',
                        }),
                        { align: 'right', width: 1 }
                      ),
                    ]),
                    ...(payment.metadata.authorizationCode
                      ? printCommands.table([
                          ...printCommands.text(
                            intl.formatMessage(translations.authorizationCode),
                            { width: 1 }
                          ),
                          ...printCommands.text(
                            payment.metadata.authorizationCode,
                            { align: 'right', width: 1 }
                          ),
                        ])
                      : []),
                    ...printCommands.table([
                      ...printCommands.text(
                        intl.formatMessage(translations.total),
                        { width: 1 }
                      ),
                      ...printCommands.text(
                        formatCurrency(payment.amountPaid || 0),
                        { align: 'right', width: 1 }
                      ),
                    ]),
                    ...(payment.metadata.input
                      ? printCommands.table([
                          ...printCommands.text(
                            intl.formatMessage(translations.inputMethod),
                            { width: 1 }
                          ),
                          ...printCommands.text(payment.metadata.input, {
                            align: 'right',
                            width: 1,
                          }),
                        ])
                      : []),
                    ...printCommands.feed(),
                  ]
                : []),
              ...printCommands.line(),
              ...printCommands.feed(),
              // Footer
              ...printCommands.text(order.number, { align: 'center' }),
              ...printCommands.text(
                intl.formatDate(undefined, {
                  dateStyle: 'full',
                  timeStyle: 'medium',
                }),
                { align: 'center' }
              ),
              ...printCommands.text(channel.name, { align: 'center' }),
              ...printCommands.feed(),
              // ...printCommands.line(),
              ...printCommands.cut(),
              ...(channel.logo
                ? printCommands.image(
                    addQueryParamsToUrl(channel.logo, { w: 400 }),
                    { align: 'center' }
                  )
                : []),
            ]),
          },
        ],
      });
    },
    [
      channel,
      createSplContent,
      enqueuePrintJobMutation,
      formatCurrency,
      intl,
      order,
      printCommands,
    ]
  );

  return {
    openCashDrawer,
    printOrder,
  };
};
