import { useCallback } from 'react';
import { replaceValuesInString } from '../utils/replaceValuesInString';
import type { Address } from '../types';

export interface PrintCommand {
  cmd: string;
}

export interface BarcodePrintCommand extends PrintCommand {
  data: string;
}

export interface ImagePrintCommand extends PrintCommand {
  url: string;
}

export interface PeripheralPrintCommand extends PrintCommand {
  channel: number;
  time: number;
}

export interface TablePrintCommand extends PrintCommand {
  columns: PrintCommand[];
}

export interface TextPrintCommand extends PrintCommand {
  data: string;
}

export const usePrintCommands = () => {
  const barcode = useCallback(
    (data: string, options = {}): BarcodePrintCommand[] => {
      return [
        {
          cmd: 'barcode',
          data,
          ...options,
        },
      ];
    },
    []
  );

  const cut = useCallback((options = {}): PrintCommand[] => {
    return [
      {
        cmd: 'cut',
        ...options,
      },
    ];
  }, []);

  const feed = useCallback((options = {}): PrintCommand[] => {
    return [
      {
        cmd: 'feed',
        ...options,
      },
    ];
  }, []);

  const image = useCallback(
    (url: string, options = {}): ImagePrintCommand[] => {
      return [
        {
          cmd: 'image',
          url,
          ...options,
        },
      ];
    },
    []
  );

  const line = useCallback((options = {}): PrintCommand[] => {
    return [
      {
        cmd: 'line',
        ...options,
      },
    ];
  }, []);

  const peripheral = useCallback((options = {}): PeripheralPrintCommand[] => {
    return [
      {
        cmd: 'peripheral',
        channel: 1,
        time: 200,
        ...options,
      },
    ];
  }, []);

  const table = useCallback(
    (columns: PrintCommand[] = [], options = {}): TablePrintCommand[] => {
      return [
        {
          cmd: 'table',
          columns,
          ...options,
        },
      ];
    },
    []
  );

  const text = useCallback((data: string, options = {}): TextPrintCommand[] => {
    return [
      {
        cmd: 'text',
        data,
        ...options,
      },
    ];
  }, []);

  const address = useCallback(
    (address: Address, format: string, options = {}) => {
      const addressString = replaceValuesInString(format, address ?? {});
      const addressLines =
        addressString?.replace(/\n\s*\n/g, '\n').split('\n') || [];

      return addressLines.flatMap((addressLine) => text(addressLine, options));
    },
    [text]
  );

  return {
    address,
    barcode,
    cut,
    feed,
    image,
    line,
    peripheral,
    table,
    text,
  };
};
