import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { useAuthentication } from '@afosto/auth-react';
import { useChannel } from '../ChannelProvider/hooks/useChannel';
import { SplashScreen } from '../SplashScreen';
import { translations } from './translations';
import type { AppSplashScreenProps } from './types';

export const AppSplashScreen = (props: AppSplashScreenProps) => {
  const { children } = props;

  const intl = useIntl();
  const location = useLocation();
  const { isAuthenticated, isAuthorizing } = useAuthentication();
  const { isInitializingChannel } = useChannel();

  const isValidRoute = !['/auth/callback', '/auth/authorize'].includes(
    location?.pathname
  );
  const isRunningAuthentication = !isAuthenticated && isAuthorizing;

  if (isValidRoute && (isRunningAuthentication || isInitializingChannel)) {
    return (
      <SplashScreen
        message={intl.formatMessage(
          translations[
            isRunningAuthentication
              ? 'authenticating'
              : 'initializingPosChannels'
          ]
        )}
      />
    );
  }

  return children;
};
