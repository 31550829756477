import { useCallback, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import {
  addAdjustmentsToOrderItems as addAdjustmentsToOrderItemsMutationRequest,
  type AddAdjustmentsToOrderItemsInput,
  removeAdjustmentsFromOrderItems as removeAdjustmentsFromOrderItemsMutationRequest,
  type RemoveAdjustmentsFromOrderItemsInput,
  updateAdjustmentForOrderItems as updateAdjustmentForOrderItemsMutationRequest,
  type UpdateAdjustmentForOrderItemsInput,
} from '../../../mutations';
import type { Adjustment, Order, OrderItem } from '../../../types';

export interface UseOrderItemDiscountOptions {
  order: Order | null;
  loadOrder: (id: string) => Promise<Order | null>;
  selectedOrderItem: OrderItem | null;
  setSelectedOrderItem: (selectedOrderItem: OrderItem | null) => void;
}

export const useOrderItemDiscount = (options: UseOrderItemDiscountOptions) => {
  const { loadOrder, order, selectedOrderItem, setSelectedOrderItem } =
    options || {};

  const [selectedItemAdjustment, setSelectedItemAdjustment] =
    useState<Adjustment | null>(null);
  const [
    showManageOrderItemAdjustmentDialog,
    setShowManageOrderItemAdjustmentDialog,
  ] = useState(false);

  const { mutateAsync: addAdjustmentsToOrderItemsMutation } = useMutation({
    mutationFn: addAdjustmentsToOrderItemsMutationRequest,
  });

  const { mutateAsync: removeAdjustmentsFromOrderItemsMutation } = useMutation({
    mutationFn: removeAdjustmentsFromOrderItemsMutationRequest,
  });

  const { mutateAsync: updateAdjustmentForOrderItemsMutation } = useMutation({
    mutationFn: updateAdjustmentForOrderItemsMutationRequest,
  });

  const closeManageOrderItemAdjustmentDialog = useCallback(() => {
    setShowManageOrderItemAdjustmentDialog(false);
  }, []);

  const exitedManageOrderItemAdjustmentDialog = useCallback(() => {
    setSelectedItemAdjustment(null);
  }, []);

  const openManageOrderItemAdjustmentDialog = useCallback(
    (adjustment?: Adjustment) => {
      if (adjustment) {
        setSelectedItemAdjustment(adjustment);
      }

      setShowManageOrderItemAdjustmentDialog(true);
    },
    []
  );

  const addAdjustmentsToOrderItems = useCallback(
    async (input: AddAdjustmentsToOrderItemsInput) => {
      const { adjustments, itemIds } = input || {};

      const response = await addAdjustmentsToOrderItemsMutation({
        adjustments,
        itemIds,
      });

      if (order) {
        const updatedOrder = await loadOrder(order.id);

        if (selectedOrderItem) {
          const updatedOrderItem = (updatedOrder?.items || []).find((item) =>
            itemIds.every((itemId) => (item.ids || [])?.includes(itemId))
          );

          if (updatedOrderItem) {
            setSelectedOrderItem(updatedOrderItem);
          }
        }
      }
    },
    [
      addAdjustmentsToOrderItemsMutation,
      loadOrder,
      order,
      selectedOrderItem,
      setSelectedOrderItem,
    ]
  );

  const removeAdjustmentsFromOrderItems = useCallback(
    async (input: RemoveAdjustmentsFromOrderItemsInput) => {
      const { adjustmentIds, itemIds } = input || {};

      await removeAdjustmentsFromOrderItemsMutation({
        adjustmentIds,
        itemIds,
      });

      if (order) {
        const updatedOrder = await loadOrder(order.id);

        if (selectedOrderItem) {
          const updatedOrderItem = (updatedOrder?.items || []).find((item) =>
            itemIds.every((itemId) => (item.ids || [])?.includes(itemId))
          );

          if (updatedOrderItem) {
            setSelectedOrderItem(updatedOrderItem);
          }
        }
      }
    },
    [
      loadOrder,
      order,
      removeAdjustmentsFromOrderItemsMutation,
      selectedOrderItem,
      setSelectedOrderItem,
    ]
  );

  const updateAdjustmentForOrderItems = useCallback(
    async (input: UpdateAdjustmentForOrderItemsInput) => {
      const { adjustment, itemIds } = input || {};

      await updateAdjustmentForOrderItemsMutation({
        adjustment,
        itemIds,
      });

      if (order) {
        const updatedOrder = await loadOrder(order.id);

        if (selectedOrderItem) {
          const updatedOrderItem = (updatedOrder?.items || []).find((item) =>
            itemIds.every((itemId) => (item.ids || [])?.includes(itemId))
          );

          if (updatedOrderItem) {
            setSelectedOrderItem(updatedOrderItem);
          }
        }
      }
    },
    [
      updateAdjustmentForOrderItemsMutation,
      loadOrder,
      order,
      selectedOrderItem,
      setSelectedOrderItem,
    ]
  );

  return {
    addAdjustmentsToOrderItems,
    closeManageOrderItemAdjustmentDialog,
    exitedManageOrderItemAdjustmentDialog,
    openManageOrderItemAdjustmentDialog,
    removeAdjustmentsFromOrderItems,
    selectedItemAdjustment,
    showManageOrderItemAdjustmentDialog,
    updateAdjustmentForOrderItems,
  };
};
