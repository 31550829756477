import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  authenticating: {
    id: 'feedback.authenticating',
    defaultMessage: 'Authenticating',
    description: 'Common label for authenticating',
  },
  initializingPosChannels: {
    id: 'feedback.initializingPosChannels',
    defaultMessage: 'Initializing POS channels',
    description: 'Common label for initializing pos channels',
  },
});
