import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Avatar } from '@afosto/components';
import { addQueryParamsToUrl } from '@afosto/utils';
import { AdjustmentsFormSection } from '../AdjustmentsFormSection';
import { Currency } from '../Currency';
import { FormDialog } from '../FormDialog';
import { useOrder } from '../OrderProvider/hooks/useOrder';
import { QuantityFormSection } from '../QuantityFormSection';
import { OrderItemActionsSection } from './components/OrderItemActionsSection';
import { validationSchema } from './validationSchema';
import { translations } from './translations';
import type { Adjustment } from '../../types';
import type {
  ManageOrderItemFormData,
  ManageOrderItemFormDialogProps,
} from './types';

export const ManageOrderItemFormDialog = (
  props: ManageOrderItemFormDialogProps
) => {
  const { formProps, item, onExited, open, ...otherProps } = props;

  const intl = useIntl();
  const {
    changeItemQuantityOnOrder,
    closeManageOrderItemDialog,
    openManageOrderItemAdjustmentDialog,
    order,
    removeItemsFromOrder,
  } = useOrder();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const defaultValues = {
    quantity: item?.quantity || 1,
  };

  const [firstDetail] = item?.details || [];
  const price = firstDetail?.pricing?.amount || 0;
  let imageUrl = item?.image;

  if (imageUrl?.startsWith('https://cdn.quicq.io')) {
    imageUrl = addQueryParamsToUrl(imageUrl, {
      h: 40,
      w: 40,
    });
  }

  const handleAddDiscount = () => {
    openManageOrderItemAdjustmentDialog();
  };

  const handleEditAdjustment = (adjustment: Adjustment) => () => {
    openManageOrderItemAdjustmentDialog(adjustment);
  };

  const handleRemoveFromOrder = async () => {
    try {
      // TODO: Do something with loading state.

      await removeItemsFromOrder({ ids: item?.ids || [] });
      closeManageOrderItemDialog();
    } catch (error) {
      // TODO: Do something with error.
    }
  };

  const handleSubmit = async (data: ManageOrderItemFormData) => {
    try {
      setIsSubmitting(true);

      if (!item) {
        setIsSubmitting(false);
        return;
      }

      const quantity =
        typeof data.quantity === 'string'
          ? Number(data.quantity)
          : data.quantity;

      await changeItemQuantityOnOrder({
        item,
        quantity,
      });

      closeManageOrderItemDialog();
      setIsSubmitting(false);
    } catch (error) {
      // TODO: Do something with error.
      setIsSubmitting(false);
      return Promise.reject(error);
    }
  };

  return (
    <FormDialog
      {...otherProps}
      formProps={{
        defaultValues,
        isSubmitting,
        submitLabel: intl.formatMessage(translations.save),
        validationSchema,
      }}
      description={<Currency currency={order?.currency} value={price} />}
      dialogHeaderProps={{
        startAdornment: <Avatar src={imageUrl} variant="rounded" />,
      }}
      onSubmit={handleSubmit}
      open={open}
      title={item?.label || item?.sku}
      TransitionProps={{ onExited }}
    >
      <QuantityFormSection />
      <OrderItemActionsSection
        disabled={isSubmitting}
        onAddDiscount={handleAddDiscount}
        onRemove={handleRemoveFromOrder}
      />
      <AdjustmentsFormSection
        adjustments={item?.adjustments || []}
        onEdit={handleEditAdjustment}
      />
    </FormDialog>
  );
};
