import { useEffect } from 'react';
import onScanJs from 'onscan.js';

export interface UseScanner {
  onScan: (barcode: string, options?: Record<string, never>) => void;
  enabled?: boolean;
  /* By default, every key is cast to uppercase. Use this setting to disable the forceful uppercase casting,
   */
  disableForcedCasing?: boolean;
}

export const useScanner = ({
  onScan,
  enabled = true,
  disableForcedCasing = false,
}: UseScanner) => {
  useEffect(() => {
    let isAttached = false;

    if (enabled && !isAttached) {
      isAttached = true;
      onScanJs.attachTo(document, {
        ignoreIfFocusOn: 'input,textarea',
        minLength: 2,
        suffixKeyCodes: [13],
        reactToPaste: true,
        onScan: (scannedCode: string) => {
          onScan(scannedCode);
        },
      });
    } else if (!enabled && isAttached) {
      onScanJs.detachFrom(document);
    }

    return () => {
      if (isAttached) {
        onScanJs.detachFrom(document);
      }
    };
  }, [enabled, disableForcedCasing, onScan]);
};
