import { FullscreenDialog } from '../FullscreenDialog';
import { useOrder } from '../OrderProvider/hooks/useOrder';
import { OrganisationActionsSection } from './components/OrganisationActionsSection';
import { OrganisationInformationSection } from './components/OrganisationInformationSection';
import { ManageOrganisationDialogProps } from './types';

export const ManageOrganisationDialog = (
  props: ManageOrganisationDialogProps
) => {
  const { organisation, onExited, ...otherProps } = props;

  const { addCustomerToOrder, order, removeCustomerFromOrder } = useOrder();
  const { organisation: orderOrganisation } = order?.customer || {};
  const isOrderOrganisation = orderOrganisation?.id === organisation?.id;

  const handleAddToOrder = async () => {
    try {
      //TODO: Do something with loading state.
      if (!organisation) {
        return;
      }

      await addCustomerToOrder({
        customer: {
          organisationId: organisation.id,
        },
      });
    } catch (error) {
      //TODO: Do something with error.
    }
  };

  const handleRemoveFromOrder = async () => {
    try {
      //TODO: Do something with loading state.
      await removeCustomerFromOrder();
    } catch (error) {
      //TODO: Do something with error.
    }
  };

  return (
    <FullscreenDialog
      {...otherProps}
      title={organisation?.name || ''}
      TransitionProps={{ onExited }}
    >
      <OrganisationActionsSection
        isOrderOrganisation={isOrderOrganisation}
        onAddToOrder={handleAddToOrder}
        onRemoveFromOrder={handleRemoveFromOrder}
      />
      {organisation && (
        <OrganisationInformationSection organisation={organisation} />
      )}
    </FullscreenDialog>
  );
};
