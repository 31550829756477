import { Typography } from '@afosto/components';
import { useChannel } from '../ChannelProvider/hooks/useChannel';
import { useOrder } from '../OrderProvider/hooks/useOrder';
import { Trash } from '../../icons/solid';
import * as Styled from './TopBar.styles';

export const TopBar = () => {
  const { channel } = useChannel();
  const { deleteOrder, order } = useOrder();

  const handleDeleteOrder = async () => {
    try {
      //TODO: Do something with loading state.
      await deleteOrder();
    } catch (error) {
      //TODO: Do something with error.
    }
  };

  return (
    <Styled.AppBar position="static">
      <Styled.Toolbar disableGutters>
        <Styled.ToolbarTitle>
          {channel && (
            <Typography variant="h4" color="gray.200">
              {channel.name}
            </Typography>
          )}
        </Styled.ToolbarTitle>
        <Styled.ToolbarActions>
          <Styled.ToolbarIconButton
            color="inherit"
            disabled={!order}
            onClick={handleDeleteOrder}
            size="large"
            variant="minimalLight1"
          >
            <Trash sx={{ fontSize: 24 }} />
          </Styled.ToolbarIconButton>
        </Styled.ToolbarActions>
      </Styled.Toolbar>
    </Styled.AppBar>
  );
};
