import { CheckoutDialog } from '../../components/CheckoutDialog';
import { ManageContactDialog } from '../../components/ManageContactDialog';
import { ManageOrganisationDialog } from '../../components/ManageOrganisationDialog';
import { ManageOrderDiscountFormDialog } from '../../components/ManageOrderDiscountFormDialog';
import { ManageOrderItemFormDialog } from '../../components/ManageOrderItemFormDialog';
import { ManageOrderItemAdjustmentFormDialog } from '../../components/ManageOrderItemAdjustmentFormDialog';
import { ManuallyAddProductFormDialog } from '../../components/ManuallyAddProductFormDialog';
import { OrderNoteFormDialog } from '../../components/OrderNoteFormDialog';
import { Receipt } from '../../components/Receipt';
import { SelectCustomerFormDialog } from '../../components/SelectCustomerFormDialog';
import { Search } from '../../components/Search';
import { SearchToolbar } from '../../components/SearchToolbar';
import { Shortcuts } from '../../components/Shortcuts';
import { useApp } from '../../components/AppProvider/hooks/useApp';
import { useCategory } from '../../components/CategoryProvider/hooks/useCategory';
import { useChannel } from '../../components/ChannelProvider/hooks/useChannel';
import { useOrder } from '../../components/OrderProvider/hooks/useOrder';
import { useScanner } from '../../hooks/useScanner';
import { GTIN_REGEX } from '../../constants/regexes';
import * as Styled from './Start.styles';

export const Start = () => {
  const { showSearch } = useApp();
  const { showSelectCategoryDialog } = useCategory();
  const { showSelectChannelDialog } = useChannel();
  const {
    addItemToOrderByGtin,
    closeAddCustomerDialog,
    closeCheckoutDialog,
    closeManageContactDialog,
    closeManageOrganisationDialog,
    closeManageOrderDiscountDialog,
    closeManageOrderItemDialog,
    closeManageOrderItemAdjustmentDialog,
    closeManuallyAddProductDialog,
    closeOrderNoteDialog,
    exitedManageContactDialog,
    exitedManageOrganisationDialog,
    exitedManageOrderItemDialog,
    exitedManageOrderItemAdjustmentDialog,
    selectedContact,
    selectedOrganisation,
    selectedItemAdjustment,
    selectedOrderItem,
    showAddCustomerDialog,
    showCheckoutDialog,
    showManageContactDialog,
    showManageOrganisationDialog,
    showManageOrderDiscountDialog,
    showManageOrderItemDialog,
    showManageOrderItemAdjustmentDialog,
    showManuallyAddProductDialog,
    showOrderNoteDialog,
  } = useOrder();
  const enableScanner =
    !showAddCustomerDialog &&
    !showCheckoutDialog &&
    !showManuallyAddProductDialog &&
    !showManageContactDialog &&
    !showManageOrganisationDialog &&
    !showManageOrderDiscountDialog &&
    !showManageOrderItemDialog &&
    !showManageOrderItemAdjustmentDialog &&
    !showOrderNoteDialog &&
    !showSelectCategoryDialog &&
    !showSelectChannelDialog;

  const handleScanInput = (input: string) => {
    if (RegExp(GTIN_REGEX).test(input)) {
      addItemToOrderByGtin(input).catch(() => {
        // Do nothing
      });
    }
  };

  useScanner({
    enabled: enableScanner,
    onScan: handleScanInput,
  });

  return (
    <>
      <Styled.Container>
        <div>
          {!showSearch && <SearchToolbar />}
          {!showSearch && <Shortcuts />}
          {showSearch && <Search />}
        </div>
        <Styled.RightLayout>
          <Receipt />
        </Styled.RightLayout>
      </Styled.Container>
      <CheckoutDialog onClose={closeCheckoutDialog} open={showCheckoutDialog} />
      <ManageContactDialog
        contact={selectedContact}
        onClose={closeManageContactDialog}
        onExited={exitedManageContactDialog}
        open={showManageContactDialog}
      />
      <ManageOrganisationDialog
        organisation={selectedOrganisation}
        onClose={closeManageOrganisationDialog}
        onExited={exitedManageOrganisationDialog}
        open={showManageOrganisationDialog}
      />
      <ManageOrderDiscountFormDialog
        onCancel={closeManageOrderDiscountDialog}
        open={showManageOrderDiscountDialog}
      />
      <ManageOrderItemFormDialog
        item={selectedOrderItem}
        onCancel={closeManageOrderItemDialog}
        onExited={exitedManageOrderItemDialog}
        open={showManageOrderItemDialog}
      />
      <ManageOrderItemAdjustmentFormDialog
        adjustment={selectedItemAdjustment}
        item={selectedOrderItem}
        onCancel={closeManageOrderItemAdjustmentDialog}
        onExited={exitedManageOrderItemAdjustmentDialog}
        open={showManageOrderItemAdjustmentDialog}
      />
      <ManuallyAddProductFormDialog
        onCancel={closeManuallyAddProductDialog}
        open={showManuallyAddProductDialog}
      />
      <OrderNoteFormDialog
        onCancel={closeOrderNoteDialog}
        open={showOrderNoteDialog}
      />
      <SelectCustomerFormDialog
        onCancel={closeAddCustomerDialog}
        open={showAddCustomerDialog}
      />
    </>
  );
};
