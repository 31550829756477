import '@afosto/fonts';
import apiClient from '@afosto/api-client';
import {
  AuthenticationProvider,
  type AuthenticationSession,
  type AuthenticationUser,
} from '@afosto/auth-react';
import {
  createTheme,
  type Theme,
  theme as defaultTheme,
  ThemeProvider,
} from '@afosto/components';
import { type ClientError, graphQLClient } from '@afosto/graphql-client';
import * as Sentry from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { Outlet, useNavigate } from 'react-router-dom';
import { AppProvider } from '../components/AppProvider';
import { AppSplashScreen } from '../components/AppSplashScreen';
import { CategoryProvider } from '../components/CategoryProvider';
import { ChannelProvider } from '../components/ChannelProvider';
import Link from '../components/Link';
import { IntlProvider } from '../components/IntlProvider';
import { OrderProvider } from '../components/OrderProvider';
import { getErrorMessage } from '../utils/getErrorMessage';
import { setupQueryClient } from '../utils/setupQueryClient';
import { APP_SCOPES } from '../constants/scopes';
import type { AxiosError } from 'axios';

const clientId = import.meta.env.VITE_CLIENT_ID;
const isRunningFromFrame = window.self !== window.top;
const queryClient = setupQueryClient();

const theme: Theme = createTheme(defaultTheme, {
  components: {
    MuiInputBase: {
      defaultProps: {
        size: 'large',
      },
    },
    MuiSelect: {
      defaultProps: {
        size: 'large',
      },
    },
  },
});

export const App = () => {
  const navigate = useNavigate();

  const handleGetErrorMessage = (error: AxiosError | ClientError | Error) => {
    return getErrorMessage(error, {
      skipSentry:
        error &&
        'response' in error &&
        error?.response &&
        [404, 400].includes(error?.response?.status),
    });
  };

  const handleSessionCleared = () => {
    Sentry.setUser(null);
  };

  const handleSessionCreated = (session: AuthenticationSession | null) => {
    apiClient.setAuthorizationHeader(session?.accessToken || '');
    graphQLClient.setAuthorizationHeader(session?.accessToken || '');
  };

  const handleUserChanged = (user: AuthenticationUser | null) => {
    if (user) {
      Sentry.setUser({
        id: user.id,
        username: user.name,
        name: user.name,
        email: user.email,
      });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <AuthenticationProvider
          clientId={clientId}
          LinkComponent={Link}
          onGetErrorMessage={handleGetErrorMessage}
          onNavigate={navigate}
          onSessionCleared={handleSessionCleared}
          onSessionCreated={handleSessionCreated}
          onUserChanged={handleUserChanged}
          scopes={APP_SCOPES}
        >
          {!isRunningFromFrame && (
            <IntlProvider>
              <ChannelProvider>
                <AppSplashScreen>
                  <AppProvider>
                    <CategoryProvider>
                      <OrderProvider>
                        <Outlet />
                      </OrderProvider>
                    </CategoryProvider>
                  </AppProvider>
                </AppSplashScreen>
              </ChannelProvider>
            </IntlProvider>
          )}
          {isRunningFromFrame && <Outlet />}
        </AuthenticationProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
};
