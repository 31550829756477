import { FullscreenDialog } from '../FullscreenDialog';
import { useOrder } from '../OrderProvider/hooks/useOrder';
import { ContactActionsSection } from './components/ContactActionsSection';
import { ContactInformationSection } from './components/ContactInformationSection';
import { getFullName } from '../../utils';
import type { ManageContactDialogProps } from './types';

export const ManageContactDialog = (props: ManageContactDialogProps) => {
  const { contact, onExited, ...otherProps } = props;

  const { addCustomerToOrder, order, removeCustomerFromOrder } = useOrder();
  const { contact: orderContact } = order?.customer || {};
  const isOrderContact = orderContact?.id === contact?.id;

  const handleAddToOrder = async () => {
    try {
      //TODO: Do something with loading state.
      if (!contact) {
        return;
      }

      await addCustomerToOrder({
        customer: {
          contactId: contact.id,
        },
      });
    } catch (error) {
      //TODO: Do something with error.
    }
  };

  const handleRemoveFromOrder = async () => {
    try {
      //TODO: Do something with loading state.
      await removeCustomerFromOrder();
    } catch (error) {
      //TODO: Do something with error.
    }
  };

  return (
    <FullscreenDialog
      {...otherProps}
      title={contact ? getFullName(contact) : ''}
      TransitionProps={{ onExited }}
    >
      <ContactActionsSection
        isOrderContact={isOrderContact}
        onAddToOrder={handleAddToOrder}
        onRemoveFromOrder={handleRemoveFromOrder}
      />
      {contact && <ContactInformationSection contact={contact} />}
    </FullscreenDialog>
  );
};
